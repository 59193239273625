.main-web-page {
  padding-left: 0;
  padding-right: 0;
  padding-top: 60px;
}
@media (min-width: 1200px) {
  .main-web-page {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
}
@media (max-width: 1200px) {
  .main-web-page {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 768px) {
  .main-web-page {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 480px) {
  .main-web-page {
    margin-left: 0;
    margin-right: 0;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    padding-top: 20px;
  }
}

main {
  margin-top: 20px;
  padding: 2rem;
}

@media (max-width: 768px) {
  main {
    margin-top: 60px;
    padding: 1rem;
  }
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 3rem;
  height: auto;
  padding-bottom: 40px;
}

.home-page h1 {
  font-size: 3rem;
  color: #324f9b;
  margin-bottom: 0;
  margin-top: 2rem;
}

.home-page h2 {
  color: #324f9b;
  font-size: 3rem;
  margin-bottom: 2rem;
  margin-top: 0;
}

.home-page p {
  font-size: 2rem;
  color: #666;
  max-width: 1000px;
  margin-bottom: 5rem;
}

@media (max-width: 768px) {
  .home-page h1 {
    font-size: 2rem;
    height: auto;
    padding: 1rem 1rem;
  }

  .home-page p {
    font-size: 1rem;
  }
}

.cta-buttons {
  display: flex;
  gap: 1rem;
}

@media (max-width: 768px) {
  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }
}

.cta-buttons button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.secondary-cta {
  background-color: #324f9b;
  color: white;
  border: none;
}

.secondary-cta:hover {
  background-color: #c7d2fe;
}

@media (max-width: 480px) {
  .home-page h1,
  .home-page h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  .home-page p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .cta-buttons button {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}
