.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 2rem;
  padding-top: 0;
  background-color: #f5f5f5;
  margin-top: 100px;
  max-height: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.footer-logo {
  flex: 1;
}

.footer-logo img {
  margin-top: 1rem;
  max-width: 10rem;
  margin-bottom: 0.7rem;
  height: auto;
}

.footer-logo p {
  font-size: 0.8rem;
  color: #888;
  margin-top: 1rem;
}

.footer-columns {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  max-width: 100%;
}

.footer-links {
  display: flex;
  gap: 4rem;
  max-width: 100%;
}

.footer-column h3 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2rem;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-column img {
  float: left;
  height: 1rem;
  width: 4rem;
}

.footer-column ul li {
  line-height: 1rem;
}

.footer-column ul li a {
  text-decoration: underline;
  color: #333;
  font-size: 0.9rem;
}

.footer-copyright {
  margin-top: 0.5rem;
  margin-left: 3rem;
}

.footer-copyright p {
  margin-right: 0;
  font-size: 0.8rem;
  color: #888;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: row;
    padding: 0.5rem;
  }

  .footer-logo img {
    height: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
  .footer-logo p {
    line-height: 1.2;
    margin-top: 3rem;
  }
  .footer-columns {
    align-items: center;
    padding-top: 0;
  }

  .footer-links {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }

  .footer-column {
    text-align: center;
    width: 100%;
  }

  .footer-column h3 {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }

  .footer-column ul li {
    line-height: 1.2rem;
    margin: 0;
  }

  .footer-column ul li a {
    font-size: 0.7rem;
    margin: 0;
  }

  .footer-copyright {
    margin: 0;
    text-align: center;
  }

  .footer-copyright p {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 0.5rem;
    margin: 0;
  }

  .footer-logo img {
    height: 1rem;
    max-width: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer-logo p {
    line-height: 1;
    margin-bottom: 2px;
    margin-top: 3.2rem;
  }
  .footer-links {
    gap: 0.5rem;
  }

  .footer-column h3 {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .footer-column ul li a {
    font-size: 0.8rem;
  }

  .footer-copyright {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .footer-copyright p {
    font-size: 0.7rem;
  }
}
