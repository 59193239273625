.calendar-container {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0.375rem;
  margin-top: 6.25rem;
  padding-top: 8rem;
}

.calendar-container h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  color: #324f9b;
  line-height: 1.2rem;
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  .calendar-container {
    margin-left: 10vh;
  }
}

@media (max-width: 768px) {
  .calendar-container {
    margin-left: 5vh;
  }
  .calendar-container h1 {
    line-height: 2rem;
  }
}

@media (max-width: 480px) {
  .calendar-container {
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5rem;
  }
  .calendar-container h1 {
    line-height: 2rem;
  }
}
