.how-it-works-avatar {
  width: 100%;
  height: 100%;
  max-height: 100vh;
}
.work-header {
  color: #324f9b;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.how-it-works {
  min-height: 100vh;
  scroll-margin-top: 1000px;
  padding-top: 100px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.work-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.work-column {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.work-column img {
  display: block;
  margin: 0 auto 1rem auto;
  max-width: 100px;
  height: auto;
}

.work-column h3 {
  color: #324f9b;
  margin-bottom: 15px;
  font-size: 1.2rem;
  text-align: left;
}

.work-column ul {
  list-style-type: none;
  padding-left: 0;
}

.work-column li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  text-align: left;
}

.work-column li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #007bff;
}
@media (max-width: 768px) {
  .work-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .work-header {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .work-column {
    padding: 15px;
  }

  .work-column h3 {
    font-size: 1rem;
  }
  .work-column h3,
  .work-column ul {
    text-align: flex-start;
  }
}
