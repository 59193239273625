.features-page {
  padding-bottom: 10rem;
  margin-top: 60px;
  padding-top: 80px;
}

.feature-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 4rem 0;
  margin: 0 auto;
  max-width: 1200px;
}

.feature-section.reversed {
  flex-direction: row-reverse;
}

.feature-content {
  flex: 0 0 40%;
}

.feature-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #324f9b;
  line-height: 1.2;
}

.feature-title .highlight {
  color: #324f9b;
}
.chat-message {
  flex: 0 0 55%;
}
.feature-description {
  font-size: 1.1rem;
  color: black;

  margin-bottom: 2rem;
  line-height: 1.6;
  text-align: justify;
}

.highlighted-text {
  color: #324f9b;
  font-weight: bold;
}

.feature-button {
  background-color: #324f9b;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feature-button:hover {
  background-color: #3451d1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay .enlarged-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.enlarged {
  display: block;
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
}

.avatar {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: zoom-in;
  transition: transform 0.3s ease-in-out;
}

.chat-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .features-page {
    padding-bottom: 5rem;
    padding-top: 40px;
  }
  .feature-section,
  .feature-section.reversed {
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1rem;
  }

  .chat-message {
    flex: 0 0 100%;
  }
  .feature-content {
    flex: 0 0 100%;
  }
  .feature-title {
    font-size: 1.8rem;
  }
  .feature-description {
    font-size: 1rem;
  }
  .avatar {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .overlay .enlarged-image {
    max-width: 95%;
    max-height: 95%;
  }
}
@media (max-width: 480px) {
  .feature-title {
    font-size: 1.5rem;
  }

  .feature-description {
    font-size: 0.9rem;
  }

  .feature-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}
