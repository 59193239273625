.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  z-index: 1000;
  box-shadow: none;
  border-radius: 30px;
  margin: 2vh 20vh 0;
  height: 80px;
}

.desktop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.logo-image img {
  height: 70px;
  width: auto;
  margin-top: 0.3rem;
}

.logo-image img:hover {
  cursor: pointer;
}

.desktop-header nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.desktop-header nav ul li {
  margin-left: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.desktop-header nav ul li:hover {
  transform: scale(1.1);
}

.desktop-header nav ul li img {
  height: 2.5rem;
  width: 2.5rem;
}

.desktop-header nav ul li img:hover {
  cursor: pointer;
}

.desktop-header nav ul li a {
  text-decoration: none;
  color: #0c0c0c;
  font-weight: 500;
}

.desktop-header nav ul li a.active {
  color: #324f9b;
  text-decoration: underline;
}

.header-button {
  background-color: #324f9b;
  color: white;
  border: none;
  padding: 1rem 1rem;
  border-radius: 40px;
  font-weight: 900;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 6px;
}

.header-button:hover {
  background-color: #3451d1;
}

.text-emoticon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Mobile Layout */
.mobile-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.mobile-header .logo img {
  height: 30px;
  width: auto;
}

.mobile-actions {
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 1rem;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0 0 30px 30px;
}

.mobile-menu.active {
  display: block;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu ul li {
  margin: 1rem 0;
}

.mobile-menu ul li a {
  text-decoration: none;
  color: #0c0c0c;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .header {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 768px) {
  .header {
    height: auto;
    margin: 10px 15px;
    border-radius: 15px;
  }

  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
  }

  .mobile-header .logo {
    padding-top: 0.5rem;
  }

  .mobile-header .header-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    margin-top: 0.1rem;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 0.5rem;
    margin: 10px 10px;
    max-width: calc(100% - 20px);
  }

  .mobile-header {
    padding-left: 0;
    align-items: center;
    margin-top: 0;
  }

  .mobile-header .logo {
    padding-top: 0;
  }

  .mobile-header .logo img {
    height: 40px;
    padding-left: 0;
    margin-top: 0.8rem;
  }

  .mobile-header .header-button {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}
