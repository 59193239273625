.benefits {
  padding: 10px 20px 40px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 80vh;
  margin-top: 1rem;
}

.benefits h1 {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #324f9b;
}

.benefits-grid {
  padding-top: 6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1024px) {
  .benefits {
    padding: 60px 15px 15px;
    height: auto;
  }

  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .benefits {
    padding: 100px 10px 20px;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.benefit-column {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefit-column img {
  width: 3rem;
  height: 3rem;
}
.benefit-column h3 {
  color: #324f9b;
  font-size: 2.5rem;
  align-self: flex-start;
}

@media (max-width: 480px) {
  .benefit-column {
    padding-top: 5px !important;
  }

  .benefit-column h3 {
    font-size: 1rem;
  }
}
